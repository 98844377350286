// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-baike-jsx": () => import("./../../../src/pages/baike.jsx" /* webpackChunkName: "component---src-pages-baike-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-brand-story-js": () => import("./../../../src/templates/brand-story.js" /* webpackChunkName: "component---src-templates-brand-story-js" */),
  "component---src-templates-ecomm-js": () => import("./../../../src/templates/ecomm.js" /* webpackChunkName: "component---src-templates-ecomm-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-global-contacts-js": () => import("./../../../src/templates/global-contacts.js" /* webpackChunkName: "component---src-templates-global-contacts-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-investors-js": () => import("./../../../src/templates/investors.js" /* webpackChunkName: "component---src-templates-investors-js" */),
  "component---src-templates-lozenges-js": () => import("./../../../src/templates/lozenges.js" /* webpackChunkName: "component---src-templates-lozenges-js" */),
  "component---src-templates-manuka-honey-js": () => import("./../../../src/templates/manuka-honey.js" /* webpackChunkName: "component---src-templates-manuka-honey-js" */),
  "component---src-templates-news-and-events-detail-js": () => import("./../../../src/templates/news-and-events-detail.js" /* webpackChunkName: "component---src-templates-news-and-events-detail-js" */),
  "component---src-templates-news-and-events-js": () => import("./../../../src/templates/news-and-events.js" /* webpackChunkName: "component---src-templates-news-and-events-js" */),
  "component---src-templates-onsale-js": () => import("./../../../src/templates/onsale.js" /* webpackChunkName: "component---src-templates-onsale-js" */),
  "component---src-templates-other-products-js": () => import("./../../../src/templates/other-products.js" /* webpackChunkName: "component---src-templates-other-products-js" */),
  "component---src-templates-propolis-js": () => import("./../../../src/templates/propolis.js" /* webpackChunkName: "component---src-templates-propolis-js" */),
  "component---src-templates-retail-stores-js": () => import("./../../../src/templates/retail-stores.js" /* webpackChunkName: "component---src-templates-retail-stores-js" */),
  "component---src-templates-table-honey-js": () => import("./../../../src/templates/table-honey.js" /* webpackChunkName: "component---src-templates-table-honey-js" */)
}

